export default [
  {
    nombres: "Carlos",
    apellidos: "Velarde",
    foto: require('../../assets/images/nosotros_equipo/equipo/3.png'),
    //puesto: "Administrador de Base de Datos",
    frase: "Compromiso",
  },
  {
    nombres: "Greco",
    apellidos: "Maldonado",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Analista de Infraestructura TI Senior",
    frase: "Persistir",
  },
  {
    nombres: "Yasmin",
    apellidos: "Alvarado",
    foto: require("../../assets/images/nosotros_equipo/equipo/14.png"),
    //puesto: "Administrative Analyst",
    frase: "Empatía",
  },
  {
    nombres: "Javier",
    apellidos: "Blanco",
    foto: require("../../assets/images/nosotros_equipo/equipo/11.png"),
    //puesto: "Cloud Full Stack Developer",
    frase: "Perseverancia",
  },
  {
    nombres: "Jorge",
    apellidos: "Salcedo",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Cloud Solutions Architect",
    frase: "Entrega",
  },
  {
    nombres: "Erick",
    apellidos: "Sánchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/22.png"),
    //puesto: "Analista de Infraestuctura",
    frase: "Constancia",
  },
  {
    nombres: "Jose",
    apellidos: "Montero",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "Analista de Seguridad TI",
    frase: "Optimista",
  },
  {
    nombres: "Luis",
    apellidos: "Yataco",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: "Operador de TI",
    frase: "Responsabilidad",
  },
  {
    nombres: "Gerardo",
    apellidos: "Navarro",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Administrador de Base de Datos",
    frase: "Dedicación",
  },
  {
    nombres: "Martin",
    apellidos: "Maras",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: "Administrador de Base de Datos",
    frase: "Empeño",
  },
  {
    nombres: "María",
    apellidos: "Riojas",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "Cloud Developer Junior",
    frase: "Resiliencia",
  },
  {
    nombres: "Kevin",
    apellidos: "Hañari",
    foto: require("../../assets/images/nosotros_equipo/equipo/7.png"),
    //puesto: "Operador de TI",
    frase: "Perseverancia",
  },
  {
    nombres: "Pamela",
    apellidos: "Alvarado",
    foto: require("../../assets/images/nosotros_equipo/equipo/15.png"),
    //puesto: "Cloud Especialist Junior",
    frase: "Empatía",
  },
  {
    nombres: "Erson",
    apellidos: "Jacobo",
    foto: require("../../assets/images/nosotros_equipo/equipo/2.png"),
    //puesto: "Cloud Specialist Junior",
    frase: "Comprensión",
  },
  {
    nombres: "Carlos",
    apellidos: "Salinas",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: "Operador de TI",
    frase: "Determinación",
  },
  {
    nombres: "Percy",
    apellidos: "Paredes",
    foto: require("../../assets/images/nosotros_equipo/equipo/16.png"),
    //puesto: "Analista de Infraestructura TI",
    frase: "Persistencia",
  },
  {
    nombres: "Nery",
    apellidos: "Baca",
    foto: require("../../assets/images/nosotros_equipo/equipo/30.png"),
    //puesto: "Asistente QA",
    frase: "Empeño",
  },
  {
    nombres: "José",
    apellidos: "Zegarra",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: "Ingeniero DevOps Junior",
    frase: "Innovador",
  },
  {
    nombres: "Ariana",
    apellidos: "Aguilar",
    foto: require("../../assets/images/nosotros_equipo/equipo/13.png"),
    //puesto: "",
    frase: "Compromiso",
  },
 
  {
    nombres: "Bryan",
    apellidos: "Vera",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "",
    frase: "Nobleza",
  },
 
  {
    nombres: "Florencia",
    apellidos: "Pasihuan",
    foto: require("../../assets/images/nosotros_equipo/equipo/30.png"),
    //puesto: "",
    frase: "Constante",
  },
  {
    nombres: "Robert",
    apellidos: "Sanchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "",
    frase: "Perseverante",
  },
  {
    nombres: "Sofía",
    apellidos: "Pisfil",
    foto: require("../../assets/images/nosotros_equipo/equipo/25.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Equilibrio",
  },
  {
    nombres: "Mireya",
    apellidos: "La Rosa",
    foto: require("../../assets/images/nosotros_equipo/equipo/13.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Optimismo",
  },
  {
    nombres: "Alan",
     apellidos: "Carlos",
     foto: require("../../assets/images/nosotros_equipo/equipo/25.png"),
     //puesto: " Administradora de Base de Datos",
     frase: "Equilibrio",
  },
  {
    nombres: "Daniel",
    apellidos: "Daza",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Compromiso",
  },
  {
    nombres: "Kiara",
    apellidos: "Colina",
    foto: require("../../assets/images/nosotros_equipo/equipo/13.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Perseverancia",
  },
  {
    nombres: "Jorge",
    apellidos: "Rodriguez",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Visionario",
  },
  {
    nombres: "Gisella",
    apellidos: "Paucar",
    foto: require("../../assets/images/nosotros_equipo/equipo/8.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Integridad",
  },
 
  {
    nombres: "Andrea",
    apellidos: "Paz",
    foto: require("../../assets/images/nosotros_equipo/equipo/29.png"),
    //puesto: "",
    frase: "Optimismo",
  },
  {
    nombres: "Jaime",
    apellidos: "Sánchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/23.png"),
    //puesto: "Líder de Tecnología y Negocios",
    frase: "Crear",
  },
];
 
 