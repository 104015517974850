import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Cabecera from "../../components/cabecera/Cabecera";
import nosotros_cultura from "../../assets/images/nosotros_cultura/nosotros_cultura.svg";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: "50px",
    },
    heading: {
      fontSize: "17px",
      fontWeight: "bold",
    },
}));

export const Politicas = () => {
  return (
    <div style={{ backgroundColor: 'var(--plomo-claro)', paddingTop: '50px', paddingBottom: '50px' }}>
      <div>
      <Cabecera
        titulo={[
          <label key="titulo_cultura" style={{ color: "var(--rojo)" }}>
            Politicas de
          </label>,
          " Privacidad",
        ]}
        icono={nosotros_cultura}
        descripcion="Comprometidos con la seguridad de tus datos"
      ></Cabecera>
      <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item sm={10} xs={12} style={{ padding: "60px 30px" }}>
              <div>
                <p style={{ textAlign: "justify" }}>
                Manantial Tecnológico agradece tu interés en nuestro sitio web y páginas relacionadas. Asimismo, comprende la importancia de proteger la información privada de nuestros visitantes y clientes. Es por ello que, a continuación, detallamos información relevante a cerca del manejo de dicha data. 
                <br></br>
                Toda información brindada que sea considerada datos personales, de acuerdo a la Ley 29733, será debida y cuidadosamente tratada, y conservada bajo nuestra responsabilidad en sistemas informáticos, tanto en el Perú como en otros países, pudiendo ser también custodiada en sistemas informáticos ubicados en la nube. 
                <br></br>
                El tratamiento y uso de tus datos personales por Manantial Tecnológico, será realizado conforme a las condiciones y fines de este apartado, ya sea por la suscripción de un contrato/formulario, aceptación de términos u otro medio similar.
                </p>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                    fontDisplay:"auto",
                  }}
                >
                  ¿Con qué fines solicitamos y utilizamos tus datos personales?
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                El fin de los datos personales solicitados a través de la web de Manantial Tecnológico es para identificar, contactar y enviar información en base al cumplimiento de las obligaciones pactadas con nuestros clientes. Asimismo, se hace uso de esa información para elaborar recursos que puedan mejorar la experiencia y calidad de servicio que ofrecemos. El uso de los datos personales y datos sensibles tendrán relación directa con el tipo de interacción que tengas con Manantial, ya sea comercial, laboral, civil o de cualquier otra naturaleza. La temporalidad del manejo de los datos personales y/o datos sensibles será indefinida a partir de la fecha en que hayan sido proporcionados. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                    fontDisplay:"auto",
                  }}
                >
                  ¿Qué datos personales obtendremos y de dónde?
                </p>
                <p style={{ textAlign: "justify" }}>
                Con el objetivo de ofrecerte una mejor atención y servicio, podemos requerir de tus datos personales a través de una solicitud directa, la visita a esta web, llamadas telefónicas y otras fuentes que se encuentren permitidas en el marco de la ley 29733. Al hacer la entrega de esta información, Manantial Tecnológico se compromete a guardarla en medios seguros, y cuyo acceso solo será permitido a personas naturales y/o jurídicas con las que Manantial Tecnológico tenga relación.
                <br></br>
                Contaremos con las medidas de seguridad adecuadas para proteger el uso de tus datos personales por parte de terceros no autorizados. En caso se requiera de alguna autoridad, tus datos personales podrán ponerse a disposición de éstas, dentro del estricto cumplimiento de la normativa vigente. El tratamiento de tus datos personales, que has puesto a disposición de Manantial Tecnológico, podrá ser efectuado conforme a los presentes términos y condiciones, por lo que desde este momento se entiende que autorizas expresamente a Manantial Tecnológico para su uso, mientras no manifiestes tu oposición mediante alguno de los medios que indica la Ley 29733.
                </p>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                    fontDisplay:"auto",
                  }}
                >
                  ¿Cómo acceder o rectificar sus datos personales o cancelar u oponerse a su uso?
                </p>
                <p style={{ textAlign: "justify" }}>
                  Tienes derecho a acceder a tus datos personales y a los detalles del tratamiento de los mismos y corregirlos, en caso sean inexactos o incompletos; cancelarlos cuando consideres que no se requieren para alguna de las finalidades señaladas en este apartado. Si están siendo utilizados para finalidades no consentidas o si ha finalizado la relación contractual o de servicio puede dirigir una solicitud de oposición a través del siguiente correo de contacto: privacidad@manantial.pe
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
    </div>
    </div>
  )
}
